@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Thambi+2&display=swap');
:root {
  --maxWidth: 1280px;
  --maxWidthHero: 95%;

  --maxWidthTop: 100%;

  --fontSSP: 'Source Sans Pro', sans-serif ,'Baloo Thambi 2';
  --fontInter: 'Inter', sans-serif ,'Baloo Thambi 2' ;
  --blue: #060D8A;
  --yellow: #E7C424;
  --white: #FFFFFF;

  --bannerWidth : 'auto';
}

/* Media query for small screens */
@media screen and (max-width: 600px) {
  :root {
    --maxWidth: 1280px;
    --bannerWidth : 'auto';

  }
}

/* Media query for large screens */
@media screen and (min-width: 1500px) {
  :root {
    --maxWidth: 1680px;
    --bannerWidth : '20%';

  }
}



/* loader */
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--blue);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Header start */
.nav-active {
  border-bottom: 3px solid white
}

#Top-bar {
  max-width: var(--maxWidthTop);
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
}

#Tob-entroll-link {
  flex-grow: 1;
  text-align: center;
}

#Tob-entroll-link a {
  font-size: 12px;
  line-height: 28px;
  font-family: var(--fontSSP)
}

#Tob-log-drop-cont {
  display: flex;
  align-items: center;
  margin: 0 .5rem;
  column-gap: 10px;
}

#Tob-log-drop-cont>div:nth-child(2) {
  margin-left: 1rem;
}

#Nav-bar-wrapper {
  max-width: var(--maxWidth);
  height: 64px;
  margin: auto;
}

#Nav-bar-ul {
  margin: 0 1.5rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

#Nav-bar-ul>li>a {
  color: var(--white);
  text-decoration: none;
  font-size: 16px;
  font-family: var(--fontSSP);
  font-weight: 700;
  
}

#Team-login-btn {
  width: 136px;
  border-radius: 21px;
  text-align: center;
  padding: 6px;
  background: var(--yellow);
  margin: 0 !important;
    float: right;

}



#Team-login-btn-2 {
  border-radius: 21px;
  text-align: center;
  padding: 6px;
  background: var(--yellow);
  width: 100px;
  margin: 1rem auto;
}

#Team-login-btn a {
  color: var(--white);
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

/* Header end */

/* Footer start */

#Footer-container {
  margin-top: 100px;
  display: grid;
  background: var(--blue);
  font-family: var(--fontSSP);
  padding: 82px 24px 24px 24px;
  margin: auto;
}

#Footer-container h1 {
  font-size: 24px;
  font-weight: 700;
  color: var(--yellow);
  margin: 0 0 48px 0;
}

.Footer-txt {
  color: var(--white);
}

#Footer-txt-content {
  max-width: 250px;
  font-size: 15px;
  font-weight: 400;
  margin: 32px 0px 100px;

}

#Footer-container h2 {
  font-size: 16px;
  font-weight: 700;
}

#Footer-subscribe-btn {
  border: none;
  height: 34px;
  width: 112px;
  border-radius: 16px;
  background: var(--yellow);
  margin-left: -30px;
}

#Footer-email {
  width: 300px;
  height: 24px;
  border: none;
  padding: 5px;
  border-radius: 16px;
  outline: none;
}

#Footer-subscribe-btn-container {
  margin: 32px 0 64px 0;
}

.Footer-ul-cont {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Footer-ul-cont li {
  margin-bottom: 16px;
}

.Footer-ul-cont li a {
  text-decoration: none;
  color: var(--white);
}

#Footer-social-logo-cont {
  display: flex;
  column-gap: 1rem;
}

#Footer-social-logo-cont span {
  background: var(--yellow);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#copy-rights {
  font-size: 10px;
  font-weight: 400;
  color: var(--yellow);

}

#copy-rights-2 {
  font-size: 10px;
  font-weight: 400;
  color: var(--yellow);
}

/* Footer end */

/* Home start */

#Points-table tr:nth-child(even) {
  background: #D3D3D3;
}

#summery-container h2 {
  margin: 0;
}

.social-link {
  font: 600 12px var(--fontSSP);
}

.social-link a {
  text-decoration: none;
}

/* Home end */

/* Leagues start */

#Leagues-wrapper {
  display: grid;
}

#Leagues-wrapper div {
  border-radius: 12px;
  background: var(--blue);
  font: 700 24px var(--fontSSP);
  display: flex;
  justify-content: center;
  align-items: center;
}


#WhoweareTab button:hover {
  color: var(--blue);
  border-bottom: 2px solid var(--blue) !important;
}

#WhoweareTab button.active {
  color: var(--blue);
  border-bottom: 2px solid var(--blue) !important;
}

#division-tab button {
  color: white;
}

#division-tab button:hover {
  color: var(--yellow);
}

#division-tab button.active {
  color: var(--yellow);
}

/* responsive */
.hamburger-container {
  display: none;
  /* position: relative; */
}

#hamburger {
  /* position: absolute; */
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
}

#popup-close-btn {
  /* position: absolute; */
  display: none;
  cursor: pointer;
  color: white;
  /* animation: fadein .9s; */
  /* transition: all 0.5s;
  -webkit-transition: all 0.5s; */
}

#score-card th {
  text-align: start;
  padding-left: 1rem;
}

#score-card td {
  padding-left: 1rem;
}

#score-card tr {
  height: 30px;
}

#score-card tr:nth-child(even) {
  background: #D3D3D3;
}

@media only screen and (max-width: 950px) {
  .nav-link {
    display: none;
  }

  #Footer-txt-content {
    max-width: 100%;
    font-size: 15px;
    font-weight: 400;
    margin: 42px 0;
  
  }

  .hamburger-container {
    display: block;
  }
}

.popup-navbar-content {
  position: fixed;
  width: 50%;
  /* transition: .9s; */
  z-index: 2;
  right: -70%;
  top: 96px;
  height: 93.6%;
  background-color: var(--blue);
  /* backdrop-filter: blur(21px); */
  -webkit-backdrop-filter: blur(21px);
  box-shadow: 0px 5px 6px #00000025;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

.popup-navbar-content>ul>li {
  list-style-type: none;
  margin-top: 25px;
}

.popup-navbar-content>ul {
  margin-top: 3rem;
}

.popup-navbar-content>ul>li>a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

#result>div {
  margin: auto;
}

@media only screen and (max-width: 860px) {
  .popup-navbar-content>ul {
    display: block;
    text-align: center;
  }

  .popup-navbar-content>ul>li {
    display: block;
    text-align: center;
    margin-right: 0;
  }


  .hamburger {
    display: block;
  }

  #ads {
    width: 100%;
  }

  #Points-table {
    width: 100%;
  }

  #ads-points-container {
    flex-direction: column;
    row-gap: 1rem;
  }

  #summery {
    flex-flow: column;
    row-gap: 1rem;
    align-items: center;
  }

  #summery-btn {
    display: flex;
    flex-flow: column;
    row-gap: 1rem;
  }

  #btcl-partner {
    flex-flow: column;
    row-gap: 2rem;
    align-items: center;
  }

  #Footer-container {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }

  #footer-logo-container {
    text-align: center;
  }

  .Footer-txt {
    text-align:center;
  }

  #Footer-subscribe-btn-container {
    text-align: center;
  }

  #Footer-subscribe-btn {
    margin-left: 0;
  }

  .mob-footer-title {
    text-align: center;
  }

  #Footer-social-logo-cont {
    justify-content: center;
  }

  #copy-rights {
    display: none;
  }

  #copy-rights-2 {
    display: block;
    margin: auto;
  }

  #Footer-subscribe-btn {
    margin-top: 1rem;
    margin-left: -30px;
  }

  #Leagues-wrapper {
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: 1rem;
  }

  .leagues {
    width: 100%;
    height: 70px;
  }

  .summery-counts {
    width: 270px;
  }

  #teams {
    grid-template-columns: auto;
    justify-content: center;
  }

  #tap-btn-wrapper {
    grid-template-columns: auto auto;
    height: fit-content;
    padding: 1rem 3rem;
    row-gap: 1rem;
  }

  #btcl-100-club-txt {
    font: 400 14px var(--fontInter);
    margin-bottom: 2rem;
  }

  #Points-table {
    font: 400 .5rem var(--fontSSP);
  }

  #team-player-container {
    width: fit-content;
    grid-template-columns: auto;
  }

  #who-area-btn-wrapper {
    grid-template-columns: auto auto;
    padding-top: 1rem;
  }

  #committees-wrapper {
    grid-template-columns: auto;
    justify-content: center;
  }

  #main-slider-container {
    margin: 0;
  }

  #main-slider-container img {
    border-radius: 0;
  }

  #hero {
    flex-flow: column;
  }

  #hero-container {
    height: auto;
  }

  #ad-image {
    display: inline;
    width: 100%;
  }

  #side-banner-container {
    width: 100%;
    margin-top: 2rem;
  }

  #slide-heading {
    font-size: 1.5rem;
  }

  #social-link-wrapper {
    column-gap: 1rem;
  }

  #division-tab {
    margin: 0 .5rem;
  }

  #score-card {
    font-size: .5rem;
  }

  #Nav-bar-ul>li {
    margin-right: 0;
  }
}

@media only screen and (min-width: 860px) {
  #ads-points-container {
    display: flex;
    row-gap: 1rem;
  }



  #ads {
    width: 75%;
    height: 500px;
  }

  #points {
    width: 50%;
  }

  #Points-table {
    width: 100%;
    font: 400 1rem var(--fontSSP);
  }

  #summery {
    flex-flow: row;
    column-gap: 1rem;
  }

  #summery-btn {
    display: flex;
    flex-flow: row;
    column-gap: 1rem;
    justify-content: center;
  }

  #btcl-partner {
    flex-flow: row;
    column-gap: 2rem;
    justify-content: space-evenly;
  }

  #Footer-container {
    width: var(--maxWidth);
    grid-template-columns: auto auto auto auto;
  }

  #Footer-subscribe-btn {
    margin-left: -30px;
  }

  #copy-rights-2 {
    display: none;
  }

  #Leagues-wrapper {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    justify-content: space-between;
    align-content: space-between;
    width: 815px;
    height: 640px;
  }

  .leagues {
    width: 219px;
    height: 147px;
  }

  .summery-counts {
    width: 353px;
  }

  #teams {
    grid-template-columns: auto auto auto auto;
  }

  #tap-btn-wrapper {
    grid-template-columns: auto auto auto auto;
    height: 48px;
    padding: 0px 6rem;
  }

  #btcl-100-club-txt {
    font: 400 24px var(--fontInter);
  }

  #Leagues-wrapper-Container {
    width: fit-content;
  }

  #team-player-container {
    width: 1232px;
    grid-template-columns: auto auto auto auto;
  }

  #who-area-btn-wrapper {
    grid-template-columns: auto auto auto auto auto;
  }

  #committees-wrapper {
    grid-template-columns: auto auto auto auto;
  }

  #main-slider-container {
    margin: 0 1.5rem;
  }

  #main-slider-container img {
    border-radius: 0;
    border-radius: 12px;
  }

  #hero {
    flex-flow: row;

  }

  #hero-container {
    height: calc(100vh - 94px - 2rem);
  }

  #ad-image {
    display: block;
    height: 80%;
  }

  #side-banner-container {
    width: 310px;
  }

  #slide-heading {
    font-size: 64px;
  }

  #social-link-wrapper {
    column-gap: 4rem;
  }

  #division-tab {
    margin: 0 1.5rem;
  }

  #Nav-bar-ul>li {
    margin-right: 2rem;
  }
}